import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// API call to create a new template
export const CreateTemplate = async (templateData: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.post(
      `${baseUrl}/superAdmin/createTemplates`,
      templateData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to fetch the list of templates
export const getTemplatesList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.get(
      `${baseUrl}/superAdmin/templatesList`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to delete a template
export const deleteTemplate = async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.delete(
      `${baseUrl}/superAdmin/deleteTemplate?id=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to update template details
export const updateTemplateDetails = async (id: any, updatedData: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.put(
      `${baseUrl}/superAdmin/updateTemplateDetails?id=${id}`,
      updatedData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  FormControl,
  FormLabel,
  Avatar,
  useToast,
  Card,
  // Textarea,
} from "@chakra-ui/react";
import { IoMdSend } from "react-icons/io";
import { TiAttachment } from "react-icons/ti";
import { RiExternalLinkLine } from "react-icons/ri";
import { TicketDetailsWithReply, updateSupportTicket } from "services/ticket";
import { TiMessages } from "react-icons/ti";
import { formatDateAmPm } from "utils/utils";
import Loader from "components/Loader/Loader";
const TicketDetailsModal = ({
  isOpen,
  handleClose,
  ticketDetails,
  setRefresh,
  refresh,
}: any) => {
  const toast = useToast();
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState("");
  const chatContainerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [uploadedFile, setUploadedFile] = useState(null);
  const id = ticketDetails?.id;

  const [messages, setMessages] = useState([]);

  useEffect(() => {
    fetchReplies();
  }, [refresh, id, isOpen]);

  const fetchReplies = () => {
    setLoading(true);
    TicketDetailsWithReply(id)
      .then((response: any) => {
        if (response.success) {
          if (response?.data) {
            setMessages(response?.data);
            setUploadedFile(null);
            setLoading(false);
          } else {
            setMessages([]);
          }
        }
      })
      .catch((error: string) => {
        setLoading(false);
        console.error("Error Ticket list:", error);
      });
  };
  const handleMessageSend = () => {
    if (message.trim() === "") return;
    const payload = {
      text: message,
      file: uploadedFile ? uploadedFile : "",
      description: ticketDetails?.description,
    };

    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value);
    });
    updateSupportTicket(formData, id)
      .then((response: any) => {
        if (response.success) {
          setRefresh(!refresh);
          toast({
            description: response.message,
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });

          fetchReplies();
        } else {
          toast({
            description: response.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          throw new Error("creating Support Ticket failed");
        }
      })
      .catch((error: any) => {
        console.error("Error creating Support Ticket:", error);
      });
    const newMessage = {
      sender: "Agent",
      text: message,
      time: getCurrentTime(),
    };
    setMessages([...messages, newMessage]);
    setMessage("");
    scrollToBottom();
  };

  const getCurrentTime = () => {
    const now = new Date();
    return `${now.getHours()}:${now.getMinutes()}`;
  };

  const scrollToBottom = () => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop =
        chatContainerRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const profileImage = localStorage.getItem("profileImage");

  const handleFileChange = (e: any) => {
    const file = e.target.files[0];
    setUploadedFile(file);
  };

  const handleAttachmentClick = () => {
    fileInputRef.current.click();
  };

  return (
    <Modal isOpen={isOpen} onClose={handleClose} size="xl">
      <ModalOverlay />
      <ModalContent maxW={"1200px"} className="dark:bg-navy-900">
        <ModalHeader>Ticket Details</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <FormControl>
            <FormLabel>Title</FormLabel>
            <input
              className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              id="name"
              type="text"
              defaultValue={ticketDetails?.title}
              disabled
            />
          </FormControl>
          <FormControl className="mt-3">
            <FormLabel>Description</FormLabel>
            <textarea
              className="w-full appearance-none rounded border-[1px] border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10  dark:bg-navy-900 dark:focus:bg-navy-900"
              id="description"
              defaultValue={ticketDetails?.description}
              onChange={ticketDetails.handleChange}
              disabled
            />
          </FormControl>
          {ticketDetails?.attachments?.length > 0 && (
            <FormControl className="my-3">
              <FormLabel>Attachments : </FormLabel>
              <div className="flex gap-2">
                {ticketDetails?.attachments?.map((item: any, index: number) => {
                  const fileName = item.substring(item.lastIndexOf("/") + 1);
                  const fileType = fileName.substring(
                    fileName.lastIndexOf(".") + 1
                  );

                  const handleBoxClick = () => {
                    window.open(item, "_blank");
                  };

                  return (
                    <div
                      key={index}
                      onClick={handleBoxClick}
                      className="mb-2 flex w-[33%] cursor-pointer items-center space-x-2 rounded-md border border-gray-300 p-4"
                    >
                      <RiExternalLinkLine className="h-6 w-6 text-blue-500" />
                      <div className="flex-grow truncate">{fileName}</div>
                      <span className="text-xs text-gray-500">
                        {fileType.toUpperCase()}
                      </span>
                    </div>
                  );
                })}
              </div>
            </FormControl>
          )}
          <div
            className="mt-3 flex h-[300px] flex-col justify-start space-y-4 overflow-auto rounded border-[1px] border-gray-500 p-6"
            ref={chatContainerRef}
          >
            {loading && <Loader />}
            {/* Chat messages go here */}
            {!loading && messages && messages.length === 0 ? (
              <Card className="relative flex h-full w-full flex-col rounded border-[1px] border-gray-200 bg-white bg-clip-border p-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none">
                <div className="mb-auto flex flex-col items-center justify-center">
                  <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                    <TiMessages />
                  </div>
                  <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                    No Message Available
                  </h4>
                </div>
              </Card>
            ) : (
              <div className="flex flex-col gap-5 space-y-1">
                {messages.map((msg, index) => {
                  const isAgentReply = msg?.reply_user_id === null;

                  return (
                    <div
                      key={index}
                      className={`relative flex flex-row items-center gap-2 ${
                        isAgentReply ? "flex-row-reverse" : ""
                      }`}
                    >
                      <Avatar
                        size="sm"
                        name={msg.sender}
                        className="border"
                        borderColor={"blue.500"}
                        src={isAgentReply ? profileImage : ""}
                      />
                      <div className="flex flex-col">
                        <span>{formatDateAmPm(msg.reply_created_at)}</span>
                        <div
                          className={`relative min-w-[200px] max-w-[800px] rounded-lg ${
                            isAgentReply ? "border-[1px]" : " bg-blue-700"
                          } p-2`}
                        >
                          <div className="flex items-center">
                            <span className="font-semibold">
                              {!isAgentReply ? (
                                <>{msg.reply_text}</>
                              ) : (
                                <>{msg.reply_text}</>
                              )}
                            </span>
                          </div>
                        </div>
                        {msg?.reply_attachment && (
                          <a
                            href={msg.reply_attachment}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="my-2 flex cursor-pointer items-center space-x-2 rounded-md border border-gray-300 px-4 py-2"
                          >
                            <div className="flex-grow truncate">
                              {msg?.reply_attachment}
                            </div>
                          </a>
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </ModalBody>
        <FormControl className="flex justify-end">
          {uploadedFile !== null && (
            <div className="mr-5 mt-3 flex w-fit justify-end rounded-lg border-[1px] px-3 py-2">
              <p className="font-bold">Files uploaded :</p> &nbsp;
              <span className="max-w-[400px] overflow-hidden text-ellipsis whitespace-nowrap">
                {uploadedFile?.name}
              </span>
            </div>
          )}
        </FormControl>
        <ModalFooter>
          {ticketDetails?.closed_at === null && (
            <div className="relative flex w-full items-center">
              <input
                type="text"
                placeholder="Type a message..."
                className="w-full rounded border-[1px] border-gray-500 px-4 py-2 pr-10 text-[18px] leading-tight focus:border-blue-700 focus:bg-white focus:outline-none  dark:border-[1px] dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              />
              <input
                type="file"
                ref={fileInputRef}
                onChange={handleFileChange}
                className="hidden"
              />
              <TiAttachment
                onClick={handleAttachmentClick}
                className="absolute right-10 ml-2 h-8 w-8 cursor-pointer text-blue-500"
              />
              <IoMdSend
                className="absolute right-1 ml-2 h-8 w-8 cursor-pointer text-blue-500"
                onClick={handleMessageSend}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    e.preventDefault();
                    handleMessageSend();
                  }
                }}
              />
            </div>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TicketDetailsModal;

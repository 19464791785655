/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Card, useToast } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import TicketDetailsModal from "modal/TicketDetailsModal";
import { MdOutlineSupportAgent } from "react-icons/md";
import { CloseTicket, getTecketList } from "services/ticket";
import { formatDateAmPm } from "utils/utils";
import Pagination from "components/pagenation";
import DeleteModal from "modal/deleteModal";
import { IoCloseCircleSharp } from "react-icons/io5";
const TicketList = () => {
  const toast = useToast();
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [ticketDetails, setTicketDetails] = useState(false);
  const [ticketDetailsData, setTicketDetailsData] = useState([]);
  const [cardData, setCardData] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [rowId, setRowId] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentTicket = cardData?.slice(indexOfFirstUser, indexOfLastUser);

  const handleTicketDetailsClose = () => {
    setTicketDetails(false);
  };

  const ShowTicketDatails = (item: any) => {
    setTicketDetails(true);
    setTicketDetailsData(item);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleModal = (item: any) => {
    setIsConfirm(false);
    setIsModalOpen(!isModalOpen);
    setRowId(item?.id);
  };

  useEffect(() => {
    getTecketList()
      .then((response: any) => {
        setLoading(false);
        if (response.success) {
          if (response?.data) {
            setCardData(response?.data);
          } else {
            setCardData([]);
          }
        }
      })
      .catch((error: string) => {
        setLoading(false);
        console.error("Error Ticket list:", error);
      });
  }, [refresh]);

  useEffect(() => {
    if (isConfirm === true) {
      CloseTicket(rowId)
        .then((response: any) => {
          if (response.success) {
            handleClose();
            setRefresh(!refresh);
            toast({
              description: response?.message,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error: any) => {
          console.error("Error Deleting Assistant:", error);
        });
    }
  }, [isConfirm]);

  return (
    <div>
      <div className="mt-8">
        {loading && <Loader />}
        {!loading && cardData && cardData.length === 0 ? (
          <Card className="relative flex h-full w-full flex-col rounded border-[1px] border-gray-200 bg-white bg-clip-border p-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:!bg-navy-800 dark:text-white dark:shadow-none">
            <div className="mb-auto flex flex-col items-center justify-center">
              <div className="mt-2 flex items-center justify-center rounded-full bg-lightPrimary p-[26px] text-5xl font-bold text-brand-500 dark:!bg-navy-700 dark:text-white">
                <MdOutlineSupportAgent />
              </div>
              <h4 className="mb-px mt-3 text-2xl font-bold text-navy-700 dark:text-white">
                No Ticket
              </h4>
            </div>
          </Card>
        ) : (
          <div className="relative flex h-full w-full flex-col rounded border-[1px] border-gray-200 bg-white bg-clip-border p-4 shadow-md shadow-[#F3F3F3] dark:border-[#ffffff33] dark:bg-navy-800 dark:text-white dark:shadow-none">
            <table className="responsive-table w-full text-left text-sm text-gray-500 dark:text-gray-400 rtl:text-right">
              <thead className="text-black-700 border-b-2 bg-gray-50 text-xs uppercase  dark:bg-navy-800 dark:text-gray-400">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Ticket
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Created At
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Last Update
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Status
                  </th>
                  <th scope="col" className="px-6 py-3">
                    action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentTicket?.map((item, index) => (
                  <tr
                    key={index}
                    className={`border-b ${
                      index % 2 === 0 ? "even:bg-gray-50" : "odd:bg-white"
                    } ${
                      index % 2 === 0 ? "dark:bg-navy-800" : "dark:bg-navy-800"
                    }`}
                  >
                    <td
                      className="cursor-pointer whitespace-nowrap px-6 py-4 text-lg font-medium text-blue-700 hover:underline"
                      data-label="title"
                      onClick={() => ShowTicketDatails(item)}
                    >
                      {item?.title}
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                      data-label="Created At"
                    >
                      {formatDateAmPm(item?.created_at)}
                    </td>
                    <td
                      className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                      data-label="Last Used"
                    >
                      {item?.updated_at
                        ? formatDateAmPm(item?.updated_at)
                        : "-"}
                    </td>
                    <td className="p-4" data-label="Status">
                      <p
                        className={`flex items-center justify-center gap-4 rounded-full font-semibold capitalize text-[#000] ${
                          item?.closed_at === null
                            ? "bg-green-500"
                            : "bg-red-500"
                        }`}
                      >
                        {item?.closed_at === null ? "active" : "close"}
                      </p>
                    </td>
                    {item?.closed_at === null && (
                      <td className="px-6 py-4">
                        <IoCloseCircleSharp
                          onClick={() => handleModal(item)}
                          className="h-6 w-6 cursor-pointer font-medium text-red-600 hover:underline dark:text-red-500"
                        />
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <DeleteModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        setIsConfirm={setIsConfirm}
      />

      <TicketDetailsModal
        isOpen={ticketDetails}
        handleClose={handleTicketDetailsClose}
        ticketDetails={ticketDetailsData}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <Pagination
        perPage={perPage}
        allTotal={cardData?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default TicketList;

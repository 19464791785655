import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// API call to fetch objective list
export const getObjectiveList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.get(
      `${baseUrl}/superAdmin/objectiveList`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to delete an objective
export const deleteObjective = async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.delete(
      `${baseUrl}/superAdmin/deleteObjective?id=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to create a new objective
export const createObjective = async (objectiveData: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.post(
      `${baseUrl}/superAdmin/createObjective`,
      objectiveData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to update objective details
export const updateObjectiveDetails = async (id: any, updatedData: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.put(
      `${baseUrl}/superAdmin/updateObjectiveDetails?id=${id}`,
      updatedData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react";
import * as Yup from "yup";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useFormik } from "formik";

const UpdateVoiceModal = ({ isOpen, handleClose, rowData }: any) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPremium, setIsPremium] = useState(false);

  const initialRef = useRef(null);
  const finalRef = useRef(null);

  useEffect(() => {
    if (rowData) {
      formik.setValues({
        name: rowData.name,
        gender: rowData.gender,
        accent: rowData.accent,
        ai_modal: rowData.ai_modal,
        is_premium: rowData.is_premium,
        voice_sample: rowData.voice_sample,
      });
      setSelectedFile(rowData.voice_sample);
      setIsPremium(rowData.is_premium);
    }
  }, [rowData]);

  const validationSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      gender: "",
      accent: "",
      ai_modal: "",
      is_premium: "",
      voice_sample: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      let payload = {
        name: values.name,
        gender: values.gender,
        accent: values.accent,
        ai_modal: values.ai_modal,
        is_premium: isPremium,
        voice_sample: selectedFile,
      };
      console.log("payload", payload);
    },
  });

  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Modal
      isCentered
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      size={"xxl"}
    >
      <ModalOverlay />
      <ModalContent maxW={"700px"} className="dark:bg-navy-900">
        <ModalHeader>Update User</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>First Name</FormLabel>
            <input
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              id="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500">
                {formik.errors.name.toString()}
              </div>
            ) : null}
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Gender</FormLabel>
            <select
              id="gender"
              name="gender"
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              value={formik.values.gender}
              onChange={formik.handleChange}
            >
              <option value="">Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
              <option value="other">other</option>
            </select>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>Accent</FormLabel>
            <input
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              id="accent"
              type="text"
              value={formik.values.accent}
              onChange={formik.handleChange}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>AI Modal</FormLabel>
            <input
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              id="ai_modal"
              type="text"
              value={formik.values.ai_modal}
              onChange={formik.handleChange}
            />
          </FormControl>
          <div className="flex items-center gap-4">
            <FormControl mt={4}>
              <Checkbox
                colorScheme="red"
                name="is_premium"
                defaultChecked={isPremium}
                onChange={(e) => setIsPremium(e.target.checked)}
              >
                Is Premium
              </Checkbox>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Voice Sample</FormLabel>
              <div className="mx-auto max-w-md">
                <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5">
                  <div className="space-y-1 text-center">
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500"
                      >
                        Upload File
                        <input
                          id="file-upload"
                          name="file-upload"
                          type="file"
                          className="sr-only"
                          accept=".mp3"
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </FormControl>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => formik.handleSubmit()}
          >
            Update
          </Button>
          <Button
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateVoiceModal;

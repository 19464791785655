import axios from "axios";
import { errorHandler } from "utils/errorHandler";

// API call to authenticate and login
export const LoginApi = async (credentials: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const response = await axios.post(
      `${baseUrl}/superAdmin/login`,
      credentials
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

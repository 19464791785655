import { Tag } from "@chakra-ui/react";
import Loader from "components/Loader/Loader";
import Pagination from "components/pagenation";
import WithdrawalRequestUpdateModal from "modal/WithdrawalRequestUpdateModal";
import React, { useEffect, useState } from "react";
import { MdEdit } from "react-icons/md";
import { getUserList } from "services/user";
import { getWithdrawalList } from "services/withdrawalRequest";
import Swal from "sweetalert2";
import { numberWithCommasAndDollar } from "utils/utils";

const WithdrawalRequest = () => {
  const [loading, setLoading] = useState(false);
  const [withdrawalList, setWithdrawalList] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [isUpdateModal, setIsUpdateModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  useEffect(() => {
    fetchWithdrawalList();
  }, []);
  useEffect(() => {
    if (refresh) {
      fetchWithdrawalList();
    }
  }, [refresh]);
  const fetchWithdrawalList = async () => {
    setLoading(true);
    try {
      const userData = await getUserList();
      if (userData?.success) {
        const data = await getWithdrawalList();
        if (data?.data) {
          for (let withdra of data?.data) {
            for (let user of userData?.data) {
              if (user.id === withdra.user_id) {
                withdra.name = user.first_name + " " + user.last_name;
                withdra.email = user.email;
              }
            }
          }
        }
        setWithdrawalList(data?.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to fetch withdrawal list. Please try again.",
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const filterWithdrawal = withdrawalList.slice(
    indexOfFirstUser,
    indexOfLastUser
  );
  const handleEditModal = (item: any) => {
    setSelectedItem(item);
    setIsUpdateModal(true);
  };
  const handleUpdateClose = () => {
    setIsUpdateModal(false);
  };
  return (
    <div className="mt-8">
      {loading ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto rounded border-[1px] border-gray-200 p-3 shadow-md dark:border-[#ffffff33] dark:bg-navy-900 sm:rounded-lg ">
          <table className="responsive-table text-black w-full text-left text-sm dark:text-gray-400 rtl:text-right">
            <thead className="text-black-700 border-b-2 text-xs uppercase  dark:bg-navy-900 dark:text-white">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Amount
                </th>
                <th scope="col" className="px-6 py-3">
                  Status
                </th>
                <th scope="col" className="px-6 py-3">
                  Action
                </th>
              </tr>
            </thead>
            <tbody>
              {filterWithdrawal?.map((item, index) => (
                <tr
                  key={index}
                  className={`border-b ${
                    index % 2 === 0 ? "even:bg-gray-50" : "odd:bg-white"
                  } ${
                    index % 2 === 0
                      ? "dark:bg-navy-900 dark:text-white"
                      : "dark:bg-navy-900 dark:text-white"
                  }`}
                >
                  <th
                    scope="row"
                    className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                  >
                    {item?.name}
                  </th>
                  <td className="px-6 py-4">{item?.email}</td>
                  <td className="px-6 py-4">
                    {numberWithCommasAndDollar(item?.amount || 0)}
                  </td>
                  <td className="px-6 py-4">
                    {item?.tran_status === 0 ||
                      (item?.tran_status === "0" && (
                        <Tag variant="solid" colorScheme="blue">
                          Pending
                        </Tag>
                      ))}
                    {item?.tran_status === 1 ||
                      (item?.tran_status === "1" && (
                        <Tag variant="solid" colorScheme="green">
                          Approve
                        </Tag>
                      ))}
                    {item?.tran_status === 0 ||
                      (item?.tran_status === "2" && (
                        <Tag variant="solid" colorScheme="red">
                          Disapprove
                        </Tag>
                      ))}
                  </td>
                  <td className="px-6 py-4">
                    <MdEdit
                      onClick={() => handleEditModal(item)}
                      className="h-6 w-6 cursor-pointer font-medium text-blue-600 hover:underline dark:text-blue-500"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        perPage={perPage}
        allTotal={withdrawalList.length}
        paginate={paginate}
        currentPage={currentPage}
      />
      <WithdrawalRequestUpdateModal
        isOpen={isUpdateModal}
        handleClose={handleUpdateClose}
        rowData={selectedItem}
        setRefresh={setRefresh}
        refresh={refresh}
      />
    </div>
  );
};

export default WithdrawalRequest;

import Loader from "components/Loader/Loader";
import Pagination from "components/pagenation";
import React, { useEffect, useState } from "react";
import { getEnterpriseInquiryList } from "services/enterpriseInquiry";
import Swal from "sweetalert2";
import { formatDateAmPm } from "utils/utils";

const EnterpriseInquiry = () => {
  const [loading, setLoading] = useState(false);
  const [withdrawalList, setWithdrawalList] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  useEffect(() => {
    fetchWithdrawalList();
  }, []);
  const fetchWithdrawalList = async () => {
    setLoading(true);
    try {
      const data = await getEnterpriseInquiryList();
      if (data?.data) {
        setWithdrawalList(data?.data);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Swal.fire({
        icon: "error",
        title: "Error!",
        text: "Failed to fetch Enterprise Inquiry list. Please try again.",
      });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const filterWithdrawal = withdrawalList.slice(
    indexOfFirstUser,
    indexOfLastUser
  );
  return (
    <div className="mt-8">
      {loading ? (
        <Loader />
      ) : (
        <div className="relative overflow-x-auto rounded border-[1px] border-gray-200 p-3 shadow-md dark:border-[#ffffff33] dark:bg-navy-900 sm:rounded-lg ">
          <table className="responsive-table text-black w-full text-left text-sm dark:text-gray-400 rtl:text-right">
            <thead className="text-black-700 border-b-2 text-xs uppercase  dark:bg-navy-900 dark:text-white">
              <tr>
                <th scope="col" className="px-6 py-3">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Email
                </th>
                <th scope="col" className="px-6 py-3">
                  Phone
                </th>
                <th scope="col" className="px-6 py-3">
                  Website
                </th>
                <th scope="col" className="px-6 py-3">
                  Region Languages
                </th>
                <th scope="col" className="px-6 py-3">
                  Use Case
                </th>
                <th scope="col" className="px-6 py-3">
                  Call Per Month
                </th>
                <th scope="col" className="px-6 py-3">
                  Created At
                </th>
              </tr>
            </thead>
            <tbody>
              {filterWithdrawal?.map((item, index) => (
                <tr
                  key={index}
                  className={`border-b ${
                    index % 2 === 0 ? "even:bg-gray-50" : "odd:bg-white"
                  } ${
                    index % 2 === 0
                      ? "dark:bg-navy-900 dark:text-white"
                      : "dark:bg-navy-900 dark:text-white"
                  }`}
                >
                  <th
                    data-label="Name"
                    className="whitespace-nowrap px-6 py-4 font-medium text-gray-900 dark:text-white"
                  >
                    {item?.name}
                  </th>
                  <td className="px-6 py-4" data-label="Email">
                    {item?.email}
                  </td>
                  <td className="px-6 py-4" data-label="Phone">
                    {item?.phone}
                  </td>
                  <td className="px-6 py-4" data-label="Website">
                    {item?.website}
                  </td>
                  <td className="px-6 py-4" data-label="Region Languages">
                    {item?.region_languages}
                  </td>
                  <td className="px-6 py-4" data-label="Use Case">
                    {item?.use_case}
                  </td>
                  <td className="px-6 py-4" data-label="Call Per Month">
                    {item?.calls_per_month}
                  </td>
                  <td className="px-6 py-4" data-label="Created At">
                    {formatDateAmPm(item?.created_at)}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
      <Pagination
        perPage={perPage}
        allTotal={withdrawalList.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default EnterpriseInquiry;

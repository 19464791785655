/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { MdDelete, MdEdit } from "react-icons/md";
import DeleteModal from "modal/deleteModal";
import { useToast } from "@chakra-ui/react";
import Swal from "sweetalert2";
import Loader from "components/Loader/Loader";
import { deleteTemplate, getTemplatesList } from "services/template";
import AddTemplateModal from "modal/addTemplate";
import UpdateTemplateModal from "modal/updateTemplateModal";
import Pagination from "components/pagenation";

const TemplateList = () => {
  const toast = useToast();
  const [isAddTemplate, setIsTemplate] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalEditTemplate, setIsModalEditTemplate] = useState(false);
  const [selectedItemId, setSelectedItemId] = useState(null);

  const [templateList, setTemplateList] = useState([]);
  const [isConfirm, setIsConfirm] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [rowId, setRowId] = useState("");
  const [loading, setLoading] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [perPage] = useState(10);
  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);
  const indexOfLastUser = currentPage * perPage;
  const indexOfFirstUser = indexOfLastUser - perPage;
  const currentTemplate = templateList?.slice(
    indexOfFirstUser,
    indexOfLastUser
  );

  const handleAddTemplateClose = () => {
    setIsTemplate(false);
  };

  const handleAddTemplateModal = () => {
    setIsTemplate(!isAddTemplate);
  };

  const handleClose = () => {
    setIsModalOpen(false);
  };

  const handleModal = (item: any) => {
    setIsConfirm(false);
    setIsModalOpen(!isModalOpen);
    setRowId(item?.id);
  };

  const handleEditTemplateClose = () => {
    setIsModalEditTemplate(false);
  };

  const handleEditTemplateModal = (item: any) => {
    setIsModalEditTemplate(!isModalEditTemplate);
    setSelectedItemId(item);
  };

  useEffect(() => {
    const fetchVoiceList = async () => {
      setLoading(true);
      try {
        const data = await getTemplatesList();
        setTemplateList(data?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Failed to fetch voice list. Please try again.",
        });
      }
    };
    fetchVoiceList();
  }, [refresh]);

  useEffect(() => {
    if (isConfirm === true) {
      deleteTemplate(rowId)
        .then((response) => {
          if (response.success) {
            handleClose();
            setRefresh(!refresh);
            toast({
              description: response?.message,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          } else {
            toast({
              description: response?.message,
              status: "info",
              duration: 5000,
              isClosable: true,
              position: "top-left",
            });
          }
        })
        .catch((error) => {
          console.error("Error Deleting Assistant:", error);
        });
    }
  }, [isConfirm]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="mt-4">
      {loading ? (
        <Loader />
      ) : (
        <>
          <div className="mb- mb-4 flex justify-end">
            <button
              onClick={() => handleAddTemplateModal()}
              className="focus:shadow-outline rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-700 focus:outline-none"
            >
              Add Template
            </button>
          </div>
          <div className="relative overflow-x-auto rounded border-[1px] border-gray-200 p-3 shadow-md dark:border-[#ffffff33] dark:bg-navy-900 sm:rounded-lg ">
            <table className="responsive-table text-black w-full text-left text-sm dark:text-gray-400 rtl:text-right">
              <thead className="text-black-700 border-b-2 text-xs uppercase  dark:bg-navy-900 dark:text-white">
                <tr>
                  <th scope="col" className="px-6 py-3">
                    Name
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Details
                  </th>
                  <th scope="col" className="px-6 py-3">
                    Action
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentTemplate?.map((item: any, index: number) => (
                  <tr
                    key={index}
                    className={`} 
                    border-b ${
                      index % 2 === 0
                        ? "dark:bg-navy-900 dark:text-white"
                        : "dark:bg-navy-900 dark:text-white"
                    }`}
                  >
                    <th
                      scope="row"
                      className="text-black-700 whitespace-nowrap px-6 py-4 font-medium dark:text-white"
                    >
                      {item.name}
                    </th>
                    <th
                      scope="row"
                      className="text-black-700 whitespace-nowrap px-6 py-4 font-medium dark:text-white"
                    >
                      {item.details}
                    </th>
                    <td className="flex gap-2 px-6 py-4">
                      <div className="flex gap-4">
                        <MdEdit
                          onClick={() => handleEditTemplateModal(item)}
                          className="h-6 w-6 cursor-pointer font-medium text-blue-600 hover:underline dark:text-blue-500"
                        />
                        <MdDelete
                          onClick={() => handleModal(item)}
                          className="h-6 w-6 cursor-pointer font-medium text-red-600 hover:underline dark:text-red-500"
                        />
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </>
      )}
      <DeleteModal
        isOpen={isModalOpen}
        handleClose={handleClose}
        setIsConfirm={setIsConfirm}
      />
      <AddTemplateModal
        isOpen={isAddTemplate}
        handleClose={handleAddTemplateClose}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <UpdateTemplateModal
        isOpen={isModalEditTemplate}
        handleClose={handleEditTemplateClose}
        rowData={selectedItemId}
        setRefresh={setRefresh}
        refresh={refresh}
      />
      <Pagination
        perPage={perPage}
        allTotal={templateList?.length}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  );
};

export default TemplateList;

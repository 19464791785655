/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useEffect } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateRolesDetails } from "services/role";
import Swal from "sweetalert2";

const UpdateRoleAddModal = ({
  isOpen,
  handleClose,
  rowData,
  setRefresh,
  refresh,
}: any) => {
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const ID = rowData?.id;
  useEffect(() => {
    if (rowData) {
      formik.setValues({
        role: rowData.role,
      });
    }
  }, [rowData]);

  const validationSchema = Yup.object().shape({
    role: Yup.string().required("First Name is required"),
  });

  const formik = useFormik({
    initialValues: {
      role: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const payload = {
        role: values.role,
      };
      updateRolesDetails(ID, payload)
        .then((response: any) => {
          if (response?.success) {
            setRefresh(!refresh);
            toast({
              title: "Update Role",
              description: response?.message,
              status: "success",
              duration: 5000,
              isClosable: true,
              position: "top-right",
            });
            handleClose();
            resetForm();
          } else {
            Swal.fire({
              icon: "info",
              title: "Update Role",
              text: response.message,
            });
          }
        })
        .catch((error: string) => {
          console.error("Error Update Role:", error);
        });
    },
  });

  return (
    <Modal
      isCentered
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      size={"xxl"}
    >
      <ModalOverlay />
      <ModalContent maxW={"700px"} className="dark:bg-navy-900">
        <ModalHeader>Update Role</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Role Name</FormLabel>
            <input
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              id="role"
              type="text"
              value={formik.values.role}
              onChange={formik.handleChange}
            />
            {formik.touched.role && formik.errors.role ? (
              <div className="text-red-500">
                {formik.errors.role.toString()}
              </div>
            ) : null}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => formik.handleSubmit()}
          >
            Update
          </Button>
          <Button
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateRoleAddModal;

/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/modal";
import { Button, FormControl, FormLabel, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateTemplateDetails } from "services/template";

const UpdateTemplateModal = ({
  isOpen,
  handleClose,
  rowData,
  setRefresh,
  refresh,
}: any) => {
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const ID = rowData?.id;
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    details: Yup.string().required("Details is required"),
  });

  useEffect(() => {
    if (rowData) {
      formik.setValues({
        name: rowData.name,
        details: rowData.details,
      });
    }
  }, [rowData]);

  const formik = useFormik({
    initialValues: {
      name: "",
      details: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const payload = {
        name: values.name,
        details: values.details,
        categories: "test",
      };
      updateTemplateDetails(ID, payload).then((response: any) => {
        if (response.status === 200 || response.status === 201) {
          setRefresh(!refresh);
          handleClose();
          toast({
            description: response.message,
            status: "success",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          resetForm();
        } else {
          toast({
            description: response.message,
            status: "info",
            duration: 8000,
            isClosable: true,
            position: "top-left",
          });
          throw new Error("Template update failed");
        }
      });
    },
  });

  return (
    <Modal
      isCentered
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      size={"xxl"}
    >
      <ModalOverlay />
      <ModalContent maxW={"700px"} className="dark:bg-navy-900">
        <ModalHeader>Update Template</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Name</FormLabel>
            <input
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              id="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="text-red-500">
                {formik.errors.name.toString()}
              </div>
            ) : null}
          </FormControl>
          <FormControl>
            <FormLabel>Details</FormLabel>
            <input
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              id="details"
              type="text"
              value={formik.values.details}
              onChange={formik.handleChange}
            />
            {formik.touched.details && formik.errors.details ? (
              <div className="text-red-500">
                {formik.errors.details.toString()}
              </div>
            ) : null}
          </FormControl>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => formik.handleSubmit()}
          >
            Update
          </Button>
          <Button
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default UpdateTemplateModal;

import React, { useRef } from "react";
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Button,
  FormControl,
  FormLabel,
  useToast,
  RadioGroup,
  Stack,
  Radio,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { updateWithdrawalrequest } from "services/withdrawalRequest";

import "react-datepicker/dist/react-datepicker.css";
const WithdrawalRequestUpdateModal = ({
  isOpen,
  handleClose,
  rowData,
  setRefresh,
  refresh,
}: any) => {
  const toast = useToast();
  const initialRef = useRef(null);
  const finalRef = useRef(null);
  const validationSchema = Yup.object().shape({
    status: Yup.string().required("Status is required"),
    description: Yup.string().required("Description is required"),
  });

  const formik = useFormik({
    initialValues: {
      status: rowData?.tran_status || "1",
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      const payload = {
        withdrawal_id: rowData?.id,
        tran_id: rowData?.tran_id,
        status: values.status,
        description: values.description,
      };
      updateWithdrawalrequest(payload)
        .then((response: any) => {
          if (response.status === 200) {
            setRefresh(!refresh);
            toast({
              description: response.message,
              status: "success",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            resetForm();
            handleClose();
          } else {
            toast({
              description: response.message,
              status: "info",
              duration: 8000,
              isClosable: true,
              position: "top-left",
            });
            throw new Error("Withdrawal request update failed");
          }
        })
        .catch((error: any) => {
          console.error("Error creating template:", error);
        });
    },
  });
  const handleRadioChange = (value: any) => {
    formik.setFieldValue("status", value);
  };
  return (
    <Modal
      isCentered
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      size={"xxl"}
    >
      <ModalOverlay />
      <ModalContent maxW={"700px"} className="dark:bg-navy-900">
        <ModalHeader>Withdrawal Request Update</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <RadioGroup
            name="status"
            value={formik.values.status}
            onChange={handleRadioChange}
          >
            <Stack spacing={5} direction="row">
              <Radio colorScheme="green" value="1">
                Approve
              </Radio>
              <Radio colorScheme="red" value="2">
                Disapprove
              </Radio>
            </Stack>
          </RadioGroup>
          {formik.touched.status && formik.errors.status ? (
            <div className="text-red-500">
              {formik.errors.status.toString()}
            </div>
          ) : null}
          <FormControl className="mt-5">
            <FormLabel>Description</FormLabel>
            <textarea
              className="mb-4 w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              id="description"
              rows={3}
              value={formik.values.description}
              onChange={formik.handleChange}
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="text-red-500">
                {formik.errors.description.toString()}
              </div>
            ) : null}
          </FormControl>
        </ModalBody>

        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => formik.handleSubmit()}
          >
            Update
          </Button>
          <Button
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default WithdrawalRequestUpdateModal;

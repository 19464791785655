import axios, { AxiosRequestConfig } from "axios";
import { errorHandler } from "utils/errorHandler";

// API call to fetch the list of industries
export const getIndustryList = async () => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.get(
      `${baseUrl}/superAdmin/industryList`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to delete an industry
export const deleteIndustry = async (id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.delete(
      `${baseUrl}/superAdmin/deleteIndustrie?id=${id}`,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to create a new industry
export const createIndustry = async (industryData: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.post(
      `${baseUrl}/superAdmin/createIndustries`,
      industryData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

// API call to update industry details
export const updateIndustryDetails = async (updatedData: any, id: any) => {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  try {
    const headers = {
      Authorization: "Bearer " + localStorage.getItem("token"),
    };
    const config: AxiosRequestConfig = {
      headers: headers,
    };
    const response = await axios.put(
      `${baseUrl}/superAdmin/updateIndustryDetails?id=${id}`,
      updatedData,
      config
    );
    return response.data;
  } catch (error) {
    errorHandler(error);
  }
};

import React, { useRef, useState } from "react";
import * as Yup from "yup";
import {
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { useFormik } from "formik";
import { createCallVoice, getVoiceList } from "services/voices";
import Swal from "sweetalert2";
import {
  AutoComplete,
  AutoCompleteInput,
  AutoCompleteItem,
  AutoCompleteList,
} from "@choc-ui/chakra-autocomplete";

import { countries } from "../utils/utils";
const VoiceModal = ({ isOpen, handleClose, setRefresh, refresh }: any) => {
  const toast = useToast();
  const [selectedFile, setSelectedFile] = useState(null);
  const [isPremium, setIsPremium] = useState(false);
  const [isAccent, setIsAccent] = useState("");
  const initialRef = useRef(null);
  const finalRef = useRef(null);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("First Name is required"),
    gender: Yup.string().required("Please Select Gender is required"),
    ai_modal: Yup.string().required("AI Modal is required"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      gender: "",
      accent: "",
      ai_modal: "",
      is_premium: "",
      voice_sample: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values: any, { resetForm }) => {
      let payload = {
        name: values.name,
        gender: values.gender,
        accent: isAccent,
        ai_modal: values.ai_modal,
        is_premium: isPremium,
        voice_sample: selectedFile,
        price_per_minute: 0.1,
      };

      const formData = new FormData();
      Object.entries(payload).forEach(([key, value]) => {
        if (key === "voice_sample") {
          formData.append("file", value);
        } else {
          formData.append(key, value);
        }
      });

      createCallVoice(formData)
        .then((response: any) => {
          if (response?.success) {
            if (response?.status === 200) {
              setRefresh(!refresh);
              toast({
                title: "Create Voice Assistant",
                description: response?.message,
                status: "success",
                duration: 5000,
                isClosable: true,
                position: "top-right",
              });
              handleClose();
              resetForm();
              getVoiceList();
            }
          } else {
            Swal.fire({
              icon: "info",
              title: "Create Assistant",
              text: response.message,
            });
          }
        })
        .catch((error: string) => {
          console.error("Error Create Assistant:", error);
        });
    },
  });

  const handleFileChange = (e: any) => {
    setSelectedFile(e.target.files[0]);
  };

  return (
    <Modal
      isCentered
      initialFocusRef={initialRef}
      finalFocusRef={finalRef}
      isOpen={isOpen}
      onClose={handleClose}
      size={"xxl"}
    >
      <ModalOverlay />
      <ModalContent maxW={"700px"} className="dark:bg-navy-900">
        <ModalHeader>Add User</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormControl>
            <FormLabel>Model Name</FormLabel>
            <input
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              id="name"
              type="text"
              value={formik.values.name}
              onChange={formik.handleChange}
            />
            {formik.touched.name && formik.errors.name ? (
              <div style={{ color: "red" }}>
                {formik.errors.name.toString()}
              </div>
            ) : null}
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Gender</FormLabel>

            <select
              id="gender"
              name="gender"
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight  focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px]  dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              value={formik.values.gender}
              onChange={formik.handleChange}
            >
              <option value="">Select gender</option>
              <option value="male">Male</option>
              <option value="female">Female</option>
            </select>
            {formik.touched.gender && formik.errors.gender ? (
              <div style={{ color: "red" }}>
                {formik.errors.gender.toString()}
              </div>
            ) : null}
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>Accent</FormLabel>
            <AutoComplete
              openOnFocus
              onChange={(value: any) => setIsAccent(value)}
            >
              <AutoCompleteInput
                variant="filled"
                placeholder="Select Accent"
                className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              />
              <AutoCompleteList>
                {countries.map((country, cid) => (
                  <AutoCompleteItem
                    key={`option-${cid}`}
                    value={country?.abbreviation}
                    textTransform="capitalize"
                  >
                    {country?.name}
                  </AutoCompleteItem>
                ))}
              </AutoCompleteList>
            </AutoComplete>
          </FormControl>

          <FormControl mt={4}>
            <FormLabel>AI Modal</FormLabel>
            <select
              id="ai_modal"
              name="ai_modal"
              className="w-full appearance-none rounded border-2 border-gray-200 bg-gray-200 px-4 py-2 leading-tight focus:border-blue-500 focus:bg-white focus:outline-none dark:border-[1px] dark:border-white/10 dark:bg-navy-900 dark:focus:bg-navy-900"
              value={formik.values.ai_modal}
              onChange={formik.handleChange}
            >
              <option value="">Select ai_modal</option>
              <option value="Open AI">Open AI</option>
            </select>
            {formik.touched.ai_modal && formik.errors.ai_modal ? (
              <div style={{ color: "red" }}>
                {formik.errors.ai_modal.toString()}
              </div>
            ) : null}
          </FormControl>

          <div className="flex items-center gap-4">
            <FormControl mt={4}>
              <Checkbox
                colorScheme="red"
                name="is_premium"
                defaultChecked={isPremium}
                onChange={(e) => setIsPremium(e.target.checked)}
              >
                Is Premium
              </Checkbox>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Voice Sample</FormLabel>
              <div className="mx-auto max-w-md">
                <div className="mt-1 flex justify-center rounded-md border-2 border-dashed border-gray-300 px-6 pb-6 pt-5">
                  <div className="space-y-1 text-center">
                    <div className="flex text-sm text-gray-600">
                      <label
                        htmlFor="file-upload"
                        className="relative cursor-pointer rounded-md bg-white font-medium text-blue-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-500 focus-within:ring-offset-2 hover:text-blue-500"
                      >
                        Upload File
                        <input
                          id="file-upload"
                          name="file"
                          type="file"
                          className="sr-only"
                          accept=".mp3"
                          onChange={handleFileChange}
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </FormControl>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            colorScheme="blue"
            mr={3}
            onClick={() => formik.handleSubmit()}
          >
            Save
          </Button>
          <Button
            onClick={() => {
              handleClose();
              formik.resetForm();
            }}
          >
            Cancel
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default VoiceModal;
